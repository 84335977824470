/* App.css */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  justify-content: flex-start; /* 修改为flex-start实现靠上对齐 */
  margin-top: 0; /* 设置上边距为零 */
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Bubble {
  max-width: 95%; /* 设置最大宽度为容器的95% */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 使用新的类名来确保样式被应用 */
.markdown-container table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
}

.markdown-container table,
.markdown-container th,
.markdown-container td {
  border: 1px solid #ccc;
}

.markdown-container th,
.markdown-container td {
  padding: 8px;
  text-align: left;
}

.markdown-container th {
  background-color: #f2f2f2;
}
