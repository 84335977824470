:root {
  --brand-1: #2f84fa;
  --brand-2: #4692ff;
  --brand-3: #bad6ff;
  --safe-bottom: env(safe-area-inset-bottom);
}

.ChatApp,
.MessageContainer,
.Navbar,
.Message .Bubble,
.QuickReplies,
.ChatFooter {
  background-repeat: no-repeat;
  background-size: cover;
}

.Message.right .Bubble {
  background-color: #d3e7fc;
}

.QuickReplies,
.ChatFooter {
  background-color: #4958fe;
  padding-bottom: var(--safe-bottom);
}
